.why-choose-us{
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    background-color: #fff;
    align-items: center;
    text-align: center;
    margin: 7% 6% 12% 6%;
    border-width: thin;
    border-style: solid;
    border-color: #000;
}

.why-choose-us-title{
    text-align: left;
    font-weight: bolder;
    font-size: 1.90vh;
    width: 100%;
    margin: 0% 0% 0% 0%;
    padding: 5% 5% 3% 5%;
    line-height: 1.15rem;
    color: #A90253;
}

.why-choose-us-img-position img{
    padding-top: 0vw;
    width: 100%;
    height: 100%;
    margin: 0% 0% 0% 0%;
}

@media screen and (min-width: 1001px) {
    .why-choose-us{
        background-color: #fff;
        margin: 3% 15% 3% 15%;
    }  

    .why-choose-us-container{
        display: flex;
    }

    .why-choose-us-title{
        margin-top: 5%;
        font-size: 3vh;
        font-weight: 800;
        line-height: 35px;
        letter-spacing: 0rem;
        text-align: left;
        width: 100%;
        margin-left: 1%;
        margin-right: 12%;
    }
    
    .why-choose-us-img-position img{
        padding-top: 0vw;
        width: 32vw;
        height: 100%;
        margin: 0% 0% 0% 0%;
    }

    .why-choose-us-content{
        padding-top: 0vw;
        width: 100%;
        height: 100%;
        margin: 0% 0% 0% 0%;
    }
}