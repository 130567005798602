/*custom font*/
@import url(https://fonts.googleapis.com/css?family=Montserrat);

/*basic reset*/
* {
    margin: 0;
    padding: 0;
}

.row-sancor{
    width:100%
}

html {
    height: 100%;
    width: 100%;
    background: #fff; /* fallback for old browsers */
}

body {
    font-family: montserrat, arial, verdana;
    background: transparent;
}


.thanks-title{
    margin-top: 5vw;
    font-family: "Poppins";
    color: #000000;
    font-weight: 700;
    line-height:auto;
    font-size: 15vw;
    text-align: center;
}

.thanks-subtitle{
    font-family: "Poppins";
    color: #000000;
    font-weight: 200;
    line-height: auto;
    text-align: left;
    font-size: 8vw;
    padding-left: 5vw;
    padding-right: 5vw;
}

.thanks-text{
    font-family: "Poppins";
    color: #000000;
    font-weight: 600;
    font-size: 6vw;
    text-align: left;
    padding-left: 5vw;
    padding-right: 13vw;
    margin-bottom: 10%;
}

/*form styles*/
#msform-sancor {
    margin-top: 0vw;
    text-align: center;
    width: 100%;
    margin-left: 0%;
    justify-content: center;
}


#msform-sancor fieldset {
    border: 0 none;
    border-radius: 20px;
    box-sizing: border-box;
    width: 100%;
    padding: 0% 2% 0% 7%;

    /*stacking fieldsets above each other*/
    position: relative;
}

/*Hide all except first fieldset*/
#msform-sancor fieldset:not(:first-of-type) {
    display: none;
}

/*inputs*/
#msform-sancor input, #msform-sancor textarea {
    padding: 9px;
    border: 1px solid #000;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #000;
    font-size: 3vw;
}

#msform-sancor .error-text{
    border: 1px solid red;
    color: red;
    font-weight: bold;
}

#msform-sancor input:focus, #msform-sancor textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #000;
    outline-width: 0;
    transition: All 0.5s ease-in;
    -webkit-transition: All 0.5s ease-in;
    -moz-transition: All 0.5s ease-in;
    -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform-sancor .action-button-sancor {
    width: 100%;
    height: 10vw;
    background: #A90253;
    font-weight: bold;
    color: #fff;
    border: 0 none;
    border-radius: 5vw;
    cursor: pointer;
    margin-right: 0%;
    margin-top: 0%;
    margin-bottom: 10%;
}

#msform-sancor .action-button:hover, #msform-sancor .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #FFD300;
    background: #454545;
    color: white;

}

#msform-sancor .action-button-previous:hover, #msform-sancor .action-button-previous:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #C5C5F1;
}

/*headings*/
.fs-title-sancor {
    color: #000000;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.fs-subtitle-sancor {
    color: #000000;
    font-size: 15px;
    text-align: left;
    margin-bottom: 7%;
}


.step {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  /* Define different styles for different steps */
  .step.step-0 {
    opacity: 1;
    transform: scale(1);
  }
  
  .step.step-1 {
    opacity: 0;
    transform: scale(0.8);
  }
  
  .step.step-2 {
    opacity: 0;
    transform: scale(0.8);
  }

  .final-buttons-sancor{
    justify-content: center;
    width:100%;
    margin-top: 0%;
  }

  .text-input-container{
    margin: 0vw;
  }

  .selection-container-type-property label{
    margin-bottom: 3%;
}

.selection-container label{
    margin-bottom: 3%;
}

#msform-sancor select {
    width: 100%;
    height: 9vw;
    border-width: 0;
    border-radius: 10px;
    padding-left: 2vw;
    padding-right: 4vw;
    font-size: 1.5vh;
    font-family: "Poppins";
    background: url("data:image/svg+xml,%3Csvg width='26' height='6.5' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L0.875645 0.25L25.1244 0.249998L13 13Z' fill='%23080808'/%3E%3C/svg%3E") no-repeat 100% 50%;
    background-color: #fff;
    border: 1px solid #000;
    background-position: right 2vw center;
    margin-bottom: 3%;
    appearance:none;
}

#msform-sancor .checkbox-container input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #A90253; /* Color del borde */
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
}

#msform-sancor .checkbox-container input[type="checkbox"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 101%;
    height: 101%;
    background-color: #A90253; /* Color de relleno */
    transform: scale(0) translate(-50%, -50%);
    transition: transform 0.2s ease-in-out;
}

#msform-sancor .checkbox-container input[type="checkbox"]:checked::before {
    transform: scale(1) translate(-50%, -50%);
}

#msform-sancor .checkbox-container label {
    font-family: "Poppins", sans-serif;
    color: #000000;
    font-size: 0.75rem;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 5%;
}

.checkbox-error {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
    font-family: "Poppins", sans-serif;
  }

@media screen and (min-width: 1001px) {

    /*headings*/
    .fs-title-sancor {
        font-size: 3.5vh;
        font-weight: 800;
        line-height: 35px;
        letter-spacing: 0rem;
        text-align: left;
    }

    .fs-subtitle-sancor {
        font-size: 3.5vh;
        text-align: left;
        margin-bottom: 4%;
    }

    .fs-title-end-sancor {
        font-size: 5.5vh;
        font-weight: 800;
        line-height: 35px;
        letter-spacing: 0rem;
        text-align: center;
    }

    /*form styles*/
    #msform-sancor {
        margin-top: -1vw;
        margin-left: 0%;
        width: 142%;
    }

    .selection-container{
        margin-top: 0%;
        margin-bottom: 0%;
    }

    .text-input-container{
        margin-top: 3vw;
        align-self: center;
        margin-left: 0%;
        margin-right: 0%;
    }
    
    .final-buttons-sancor{
        margin-top: 0vw;
        font-size: 1.5vw;
    }
    
    #msform-sancor .action-button-sancor {
        width: 100%;
        height: 3vw;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
    }

    #msform-sancor select {
        height: 3vw;
        border-width: 0;
        border-radius: 10px;
        padding-left: 1vw;
        padding-right: 0vw;
        font-size: 2.25vh;
        background: url("data:image/svg+xml,%3Csvg width='26' height='9' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L0.875645 0.25L25.1244 0.249998L13 13Z' fill='%23080808'/%3E%3C/svg%3E") no-repeat 100% 50%;
        background-color: #fff;
        border: 1px solid #000;
        background-position: right 1vw center;
        margin-bottom: 2%;
    }

    .dropdown-container{
        display: flex;
        justify-content: left;
    }

    /*inputs*/
    #msform-sancor input, #msform-sancor textarea {
        font-size: 2.5vh;
        height: 3vw;
        padding-left: 3%;
    }

    #msform-sancor .checkbox-container label {
        font-family: "Poppins", sans-serif;
        color: #000000;
        font-size: 1.25rem;
        font-weight: 400;
        cursor: pointer;
        margin-bottom: 5%;
    }
    
    .thanks-title{
        margin-top: 5%;
        margin-left: 12%;
        padding-left: 0vw;
        padding-right: 0vw;
        font-size: 5.5vh;
        text-align: left;
    }
    
    .thanks-subtitle{
        color: #000;
        line-height: 3.5vw;
        font-size: 5vh;
        text-align: left;
        padding-left: 0vw;
        padding-right: 0vw;
        margin-left: 12%;
        margin-top: 5%;
    }
    
    .thanks-text{
        font-size: 2vw;
        text-align: left;
        padding-left: 0vw;
        padding-right: 0vw;
        width: 90%;
        margin-left: 12%;
        margin-top: 5%;
    }
    
}