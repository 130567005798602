.top-bar {
  display: grid;
  position: fixed;
  height: 35vw;
  width: 100vw;
  font-family: "Inter", sans-serif;
  background-color: #FFD300;
  z-index: 100000;
  justify-content: center;
}

.top-bar-sancor{
  display: grid;
  position: fixed;
  height: 20vw;
  width: 100vw;
  font-family: "Inter", sans-serif;
  background-color: #FFD300;
  z-index: 100000;
  justify-content: center;
  padding-top: 2.5%;
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slideout {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

.img-container-top-bar {
  width: 50vw;
}
.img-container-top-bar img {
  width: 60vw;
  margin-top: 2%;
}

.call-top-bar {
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
  height: fit-content;
  cursor: pointer;
  margin-top: 3%;
  margin-bottom: 5%;
  z-index: 100001;

  background-color: #000000;
  border-radius: 35vw;
}

.call-top-bar a{
  color: #FFD300;
}
.call-top-bar p{
  color: #FFD300;
}

.call-top-bar .floating-button .button-title {
  position: relative;
}


@media screen and (min-width: 1001px) {
  .top-bar {
    display: flex;
    height: 8vw;
    justify-content: space-between;
  }

  .top-bar-sancor {
    display: flex;
    height: 6vw;
    justify-content: space-between;
  }

  .top-bar-sancor .img-container-top-bar img {
    width: 100%;
    margin-left: 2vw;
    margin-top: -2.75vw;
  }

  .img-container-top-bar img {
    width: 100%;
    margin-left: 2vw;
    margin-top: 0.75vw;
  }
  
  .img-container-top-bar {
    width: 30vw;
  }

  .call-top-bar {
    position:relative;
    display: flex;
    right: 5vw;
    width: fit-content;
    height: 100%;
    cursor: pointer;
    z-index: 100001;
    background-color: #00000000;

    margin-top: 0%;
    margin-bottom: 0%;

    border-radius: 0vw;
  }

  .call-top-bar a{
    color: #000000;
  }
  .call-top-bar p{
    color: #000000;
  }

}
