.panel-title {
  display: flex;
}


.img-panel-overlay {
  position: fixed;
  background-color: #01010199;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.img-panel {
  margin-top: 17vw;
  z-index: -3;
}

.img-panel img{
  width: 100%;
}

.img-desk{
  display: none;
}

@media screen and (min-width: 1001px) {
  .panel-title {
    grid-template-rows: none;
  }

  .img-panel img{
    width: 100%;
  }
  
  .img-panel {
    width: 100%;
    align-self: center;
    margin-top: -2vw;
  }

  .img-mobile{
    outline: 100vw solid #01010199;
    display: none;
  }
  .img-desk{
    display: block;
  }
}
